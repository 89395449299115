import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

import {
  Root,
  Login,
  Term,
  Join,
  FindId,
  FindPw,
  Notice,
  NoticeDetail,
  ShopInfo,
  ShopDetail,
  Exercise,
  CommunityRegister,
  CommunityEdit,
  CalendarPage,
  Free,
  Program,
  ClassIndex,
  ProgramDetail,
  MyClass,
  Nonmember,
  Mypage,
  NonmemberOrder,
  Cart,
  Jspace,
  Challenge,
  Jellin,
  ProductCartOrder,
  Introduce,
  JspaceDetail,
  Search,
  LevelIntro,
  EditProfile,
  MyPoint,
  MyProgram,
  MyAlarm,
  MyFaq,
  QnaRegister,
  QnaDetail,
  MyOrder,
  MyPost,
  MyComment,
  MyOrderDetail,
  MyOrderCancel,
  MyOrderReturn,
  ClassPlay,
  ClassQnaRegister,
  ProgramOrder,
  ProductOrder,
  SnsJoin,
  Lab,
  OfflineClass,
  ShopLogin,
  NonmemberReturn,
  NonmemberCancel,
  ChallengeRegister,
  ChallengeEdit,
  MobilePayment,
  Test,
  AppleLogin,
  AppleJoin,
  XmlPage,
} from "../pages";

import { api } from "../utils/api";
import { SWRConfig } from "swr";
import AuthRoute from "../pages/route/AuthRoute";
import auth from "../utils/auth";
import ClassRoute from "../components/ClassRoute";

//AuthRoute --> 로그인이 필요한 페이지
const App = () => {
  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        if (
          res.data?.code === "SD0098" &&
          window.location.pathname !== "/sns/join"
        ) {
          return (window.location.href = "/sns/join");
        }

        return res.data?.data || res.data;
      });
    },
  };

  useEffect(() => {
    checkForAccess();

    if (window.location.href.includes("www.salondefit.co.kr"))
      window.location.replace(window.location.href.replace("www.", ""));

    //title 수정
    fetch(
      "https://api.salondefit.co.kr/api/site/name"
      // "https://dev.api.salondefit.co.kr/api/site/name"
    )
      .then((response) => response.json())
      .then((data) => (document.title = data?.data.site_name));
  }, []);

  const checkForAccess = () => {
    var varUA = navigator.userAgent;
    if (auth.getToken() !== null) {
      if (varUA.match("salondefit/Android") != null) {
        window.salondefit.postMessage(
          JSON.stringify({ type: "autoLogin", token: auth.getToken() })
        );
      } else if (varUA.match("salondefit/iOS") != null) {
        window.webkit.messageHandlers.autoLoginHandler.postMessage(
          auth.getToken()
        );
      }
    }
  };

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Root} />
          <Route exact path="/shop" component={Root} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/nonmember" component={Nonmember} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/sns/join" component={SnsJoin} />
          <Route exact path="/apple/login" component={AppleLogin} />
          <Route exact path="/apple/join" component={AppleJoin} />
          <Route exact path="/terms" component={Term} />
          <Route exact path="/find_id" component={FindId} />
          <Route exact path="/find_pw" component={FindPw} />

          <Route exact path="/payment_redirect" component={MobilePayment} />

          {/*////// 커뮤니티 -> 캘린더(CalendarPage), 수업후기(Jellin) 빼고 회원전용 */}
          <Route
            exact
            path="/community"
            component={
              auth.getToken() &&
              auth.getToken() !== null &&
              auth.getToken() !== "null" &&
              auth.getToken() !== "undefined"
                ? Notice
                : CalendarPage
            }
          />
          <Route exact path="/community/free" component={Free} />

          <Route exact path="/community/notice" component={Notice} />
          <Route
            exact
            path="/community/notice/:noticeId"
            component={NoticeDetail}
          />

          <Route
            exact
            path="/community/calendar"
            render={() => <CalendarPage isToken={auth.getToken()} />}
          />

          <ClassRoute exact path="/community/jj" component={Jspace} />

          <Route
            exact
            path="/community/jj/:noticeId"
            component={JspaceDetail}
          />
          <ClassRoute exact path="/community/exercise" component={Exercise} />
          <ClassRoute exact path="/community/challenge" component={Challenge} />
          <Route
            exact
            path="/community/jellin"
            render={() => <Jellin isToken={auth.getToken()} />}
          />

          <Route
            exact
            path="/community/:type/register"
            component={CommunityRegister}
          />
          <Route
            exact
            path="/community/challenge/make"
            component={ChallengeRegister}
          />
          <Route
            exact
            path="/community/:type/edit:editId"
            component={CommunityEdit}
          />
          <Route
            exact
            path="/community/:type/modify:editId"
            component={ChallengeEdit}
          />
          {/* //////SHOP 시작////// */}
          <Route
            exact
            path="/shop/cate:cateId/subcate:subcateId"
            component={ShopInfo}
          />
          <Route exact path="/shop/search/:keyword" component={Search} />

          <Route
            exact
            path="/shop/detail:productId"
            render={() => <ShopDetail isToken={auth.getToken()} />}
          />
          <Route exact path="/:orderType/login" component={ShopLogin} />
          <Route
            exact
            path="/shop/cart"
            render={() => <Cart isToken={auth.getToken()} />}
          />
          {/* 상품상세에서 바로 구매하기 */}
          <Route
            exact
            path="/shop/order/:productId/:memberType/:orderType"
            render={() => <ProductOrder isToken={auth.getToken()} />}
          />
          {/* 장바구니에서 바로 구매하기 */}
          <Route
            exact
            path="/shop/order/:memberType/:orderType"
            render={() => <ProductCartOrder isToken={auth.getToken()} />}
          />

          {/* //////CLASS 시작////// */}
          <Route exact path="/class" component={ClassIndex} />
          <Route exact path="/class/lab" component={Lab} />
          <Route exact path="/class/introduce" component={Introduce} />
          <Route
            exact
            path="/class/program"
            render={() => <Program isToken={auth.getToken()} />}
          />
          <Route
            exact
            path="/class/program/:programId"
            render={() => <ProgramDetail isToken={auth.getToken()} />}
          />
          {/* CLASS 회원 전용 */}
          <AuthRoute exact path="/class/myclass" component={MyClass} />
          <AuthRoute exact path="/class/play/:classId" component={ClassPlay} />
          <Route exact path="/class/xmlPage" component={XmlPage} />
          <AuthRoute
            exact
            path="/class/offline/:classId"
            component={OfflineClass}
          />
          <AuthRoute
            exact
            path="/class/qna/register/:classId"
            component={ClassQnaRegister}
          />
          <AuthRoute
            exact
            path="/class/order/:classId"
            component={ProgramOrder}
          />
          <Route exact path="/class/test" component={Test} />

          {/* //////마이페이지 회원전용////// */}
          <AuthRoute exact path="/mypage" component={Mypage} />
          <AuthRoute exact path="/mypage/level" component={LevelIntro} />
          <AuthRoute exact path="/mypage/edit" component={EditProfile} />
          <AuthRoute exact path="/mypage/point" component={MyPoint} />
          <AuthRoute exact path="/mypage/program" component={MyProgram} />
          <AuthRoute exact path="/mypage/alarm" component={MyAlarm} />
          <AuthRoute exact path="/mypage/faq" component={MyFaq} />
          <AuthRoute
            exact
            path="/mypage/qna/register"
            component={QnaRegister}
          />
          <AuthRoute exact path="/mypage/qna/:qnaId" component={QnaDetail} />
          <AuthRoute exact path="/mypage/order" component={MyOrder} />
          <AuthRoute exact path="/mypage/post" component={MyPost} />
          <AuthRoute exact path="/mypage/comment" component={MyComment} />
          <AuthRoute
            exact
            path="/mypage/order/:orderType/:orderId"
            component={MyOrderDetail}
          />
          <AuthRoute
            exact
            path="/mypage/order/return/:orderType/:orderId"
            component={MyOrderReturn}
          />
          <AuthRoute
            exact
            path="/mypage/order/cancel/:orderType/:orderId"
            component={MyOrderCancel}
          />

          {/* /////// */}
          {/* 비회원 주문조회 */}
          <Route
            exact
            path="/nonmember/order/:orderId/:emailId"
            component={NonmemberOrder}
          />
          <Route
            exact
            path="/nonmember/order/cancel/:orderId/:emailId"
            component={NonmemberCancel}
          />
          <Route
            exact
            path="/nonmember/order/return/:orderId/:emailId"
            component={NonmemberReturn}
          />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
